export const itemsListData = [
  {
    item: 'Derecho laboral',
  },
  {
    item: 'Indemnización por despido',
  },
  {
    item: 'Maltrato laboral',
  },
  {
    item: 'Diferencias salariales',
  },
  {
    item: 'Trabajo insalubre',
  },
];
